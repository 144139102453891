import _ from "lodash";
window._ = _;

import "./bootstrap";
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// import axios from 'axios';
// window.axios = axios;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

//jQuery
import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

//toastr
window.toastr = require("./components/toastr");

//flatpickr datetimerange
import "flatpickr";
const Indonesia = require("flatpickr/dist/l10n/id.js").default.id;

//select2
import "select2";
// require("./components/clipboard");

require("./components/bulma-modal-fx");

// bulma modal

// DataTable
import DataTable from "datatables.net-bm";
// import "datatables.net-buttons-bm";

// Phone Formatter
// import intlTelInput from "intl-tel-input";

// const input = document.querySelector("#phone");
// if (input) {
//     intlTelInput(input, {
//         utilsScript: "/js/components/utils.js",
//         onlyCountries: ["id"],
//         separateDialCode: true,
//         nationalMode: true,
//         hiddenInput: "phone",
//         customPlaceholder: function (
//             selectedCountryPlaceholder,
//             selectedCountryData
//         ) {
//             return "812345678";
//         },
//     });
// }
flatpickr(".daterange", {
    mode: "range",
    altInput: true,
    altFormat: "j F Y",
    // maxDate: new Date(),
    parseDate: true,
    locale: Indonesia,
});

$(document).ready(function () {
    $(".money").each(function (event) {
        // $(this).val(function (index, value) {
        //     var val = this.value;
        //     val = val.replace(/[^0-9\.]/g, "");
        //     if (val != "") {
        //         valArr = val.split(".");
        //         valArr[0] = parseInt(valArr[0], 10).toLocaleString();
        //         val = valArr.join(".");
        //     }
        //     return val;
        // });
    });
    $(".money").on("keyup", function (event) {
        var val = $(this).val();
        val = val.replace(/[^0-9\,]/g, "");
        if (val != "") {
            var valArr = val.split(",");
            console.log(valArr);
            valArr[0] = parseInt(valArr[0], 10).toLocaleString("id");
            if (valArr[1]) {
                if (valArr[1].length > 2) {
                    valArr[1] = valArr[1].slice(0, 2);
                }
            }
            val = valArr.join(",");
        }

        this.value = val;
    });

    $(".minus-money").on("keyup", function (event) {
        var val = $(this).val();
        if (val.length > 2) {
            val = val.replace(/[^0-9\,-]/g, "");
            if (val != "") {
                var valArr = val.split(",");
                console.log(valArr);
                valArr[0] = parseInt(valArr[0], 10).toLocaleString("id");
                if (valArr[1]) {
                    if (valArr[1].length > 2) {
                        valArr[1] = valArr[1].slice(0, 2);
                    }
                }
                val = valArr.join(",");
            }

            this.value = val;
        }
    });
});
// $(document).ready(function () {
//     $(".money").each(function () {
//         var n = parseInt(
//             $(this)
//                 .val()
//                 .replace(/(?!^-)\D/g, ""),
//             10
//         );
//         $(this).val(n.toLocaleString("id"));
//     });
//     $(".money").on("keyup", function () {
//         var n = parseInt(
//             $(this)
//                 .val()
//                 .replace(/(?!^-)\D/g, ""),
//             10
//         );
//         if (isNaN(n)) {
//             $(this).val(0);
//         } else {
//             $(this).val(n.toLocaleString("id"));
//         }
//     });
// });
$(".view-password").click(function () {
    var check_attr = $(this)
        .parent()
        .find('input[type="password"]')
        .attr("type");
    if (check_attr == "password") {
        $(this).parent().find('input[type="password"]').attr("type", "text");
        $(this).find("img").attr("src", "/images/icons/eye-close.svg");
    } else {
        $(this).parent().find('input[type="text"]').attr("type", "password");
        $(this).find("img").attr("src", "/images/icons/eye.svg");
    }
});
// navbar
$(".navbar-burger").click(function () {
    $("aside").toggleClass("is-active");
});
